import React, { useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { useNavigate } from 'react-router-dom';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

function ColumnsTable(props) {
  const navigate = useNavigate();
  const { tableData } = props;
  const [sorting, setSorting] = React.useState([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">NAME</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("marketcap", {
      id: "marketcap",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Market Capital
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("industry", {
      id: "industry",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Current Price
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("pe", {
      id: "pe",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">PE ratio</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  function cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [data, setData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (

    <Card extra={"w-full pb-8 p-8 h-full"}>
      <header className="relative flex items-center">
        <div className="text-2xl md:text-3xl font-bold text-navy-700 dark:text-white mr-5">
          {props.smalltitle}
        </div>
        <CardMenu first={props.first} second={props.second} third={props.third} fourth={props.fourth} indexfunction={props.indexfunction} />
      </header>

      <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-navy-700">
                        <span>
                            {cap(header.column.id)}
                          </span>
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, props.limit)
              .map((row) => {
                return (
                  <tr key={row.id} className="mx-4" style={row.id % 2 === 0 ? {'backgroundColor' : '#f7faff'} : {}}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                          // style={{'cursor' : 'pointer'}}
                        >
                          <span className={cell.column.id === 'name' ? "text-brand-500 cursor-pointer" : ""} 
                            onClick={() => {
                              if (cell.column.id === 'name') {
                                navigate(`/stock/${cell.row.original.ticker}`);
                              }
                            }}>
                            {cell.column.id === 'pe' 
                              ? cell.row.original.pe 
                              : cell.column.id === 'name' 
                              ? cell.row.original.name 
                              : cell.column.id === 'marketcap' 
                              ? cell.row.original.marketcap 
                              : cell.column.id === 'industry'
                              ? cell.row.original.industry
                              : null}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>

  );
}

export default ColumnsTable;
const columnHelper = createColumnHelper();
