import BarChart from "components/charts/BarChart";
import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";

const DailyTraffic = (props) => {
  return (
    <Card extra="pb-7 pt-6 md:p-8">
      <div className="flex flex-row md:flex items-center justify-center md:justify-start">
        <div className="ml-1 pt-2 ">
          <p className="text-3xl font-bold leading-4 mb-2">
            Predicted Price
          </p>
        </div>
        <div className="mt-2 flex items-start">
          <div className="flex items-center text-sm text-green-500">
{/*             <MdArrowDropUp className="h-5 w-5" /> */}
{/*             <p className="font-bold"> +2.45% </p> */}
          </div>
        </div>
      </div>

      <div className="h-[410px] w-full pt-2 pb-0">
        <BarChart
          chartData={props.barChartData}
          chartOptions={props.barChartOptions}
        />
      </div>
    </Card>
  );
};

export default DailyTraffic;
