import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ColumnsTable from "views/admin/tables/components/ColumnsTable";
import Navbar from "components/navbar";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';


function Main() {
  const [tableDataColumns, setTableDataColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState("Nifty50");
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkauth = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {

        }
      }catch (error) {
        navigate("/")
      }
      finally {
        setLoading(false);
      }
    };
    checkauth();
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setTableDataColumns([])
      setTotalPages(1)
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_index/${currentIndex}/${currentPage}`);
        const data = response.data.stocks;

        const formattedData = data.map((item) => ({
          name: item.name,
          marketcap: item.marketcap ? (item.marketcap / 10000000).toFixed(0) + " cr" : "N/A",
          industry: item.industry || "N/A",
          pe: item.pe || 24 ,
          ticker : item.ticker
        }));
        
        setTableDataColumns(formattedData);
        setTotalPages(response.data.pages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currentPage,currentIndex]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleIndexClick = (index) => {
    setCurrentIndex(index);
  };

  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
	        <Navbar
              logoText={"Horizon UI Tailwind React"}
              brandText="Stockefy"
            />
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  const mapping = {"Nifty50" : "Nifty 50" , "NiftyMid50" : "Nifty Midcap 50",
                   "NiftyMid100" : "Nifty Midcap 100" , "NiftySmallCap100" : "Nifty Smallcap 50"}

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full dark:!bg-navy-900" style={{'backgroundColor' : "#f2f5ff"}}>
        <main className={` h-full flex-none transition-all md:pr-2`}>
          <div className="h-full">
            <Navbar
              logoText={"hello"}
              brandText="Stockefy"
            />
            <div className='mt-1 mx-2 md:mx-12'>
              {tableDataColumns.length > 0 ? (
                <div>
                  <ColumnsTable
                    tableData={tableDataColumns}
                    smalltitle={mapping[currentIndex]}
                    limit={25}
                    first="Nifty 50"
                    second="Nifty Midcap 50"
                    third="Nifty Midcap 100"
                    fourth="Nifty Smallcap 50"
                    indexfunction={handleIndexClick}
                  />
                  <div className="flex justify-center items-center mt-4 space-x-4 pb-16">
                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                      <div
                        key={page}
                        className={`px-4 py-2 rounded-md cursor-pointer focus:outline-none ${
                          currentPage === page
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-white"
                        }`}
                        onClick={() => handlePageClick(page)}
                      >
                        {page}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>Loading data...</p>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Main;
