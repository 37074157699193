import React from "react";
import {
  MdArrowDropUp,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";

const TotalSpent = (props) => {
  return (
    <Card extra="pt-4 md:p-8 text-center h-[550px]">
      <div className="flex md:justify-between justify-center mb-4">
        <button className="linear mt-1 md:flex items-center justify-center gap-2 rounded-lg p-2 text-gray-600 transition duration-200 hover:cursor-pointer active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
        <div className="text-3xl font-bold text-navy-700 dark:text-white mr-5">
          Price Chart
        </div>
        <MdOutlineCalendarToday className="hidden md:block" />
		<ul className="flex flex-row space-x-4 p-2 mt-4 md:mt-0 rounded-xl ml-2" style={{ border: "1px solid #c0c0c0" }}>
		  <li>
		    <span
		      className={`text-sm font-medium text-gray-600 hover:bg-gray-100 p-3 rounded-xl`}
		      onClick={() => props.changeRangeFunc("5Yr")}
		    >
		      <span style={{ color: props.currentRange === "5Yr" ? "#008ffb" : "initial" }}>5Yr</span>
		    </span>
		  </li>
		  <li>
		    <span
		      className={`text-sm font-medium text-gray-600 hover:bg-gray-100 p-3 rounded-xl`}
		      onClick={() => props.changeRangeFunc("3Yr")}
		    >
		      <span style={{ color: props.currentRange === "3Yr" ? "#008ffb" : "initial" }} >3Yr</span>
		    </span>
		  </li>
		  <li>
		    <span
		      className={`text-sm font-medium text-gray-600 hover:bg-gray-100 p-3 rounded-xl`}
		      onClick={() => props.changeRangeFunc("1Yr")}
		    >
		     <span style={{ color: props.currentRange === "1Yr" ? "#008ffb" : "initial" }} >1Yr</span>
		    </span>
		  </li>
		  <li>
		    <span
		      className={`text-sm font-medium text-gray-600 hover:bg-gray-100 p-3 rounded-xl `}
		      onClick={() => props.changeRangeFunc("6M")}
		    >
		     <span style={{ color: props.currentRange === "6M" ? "#008ffb" : "initial" }} >6M</span>
		    </span>
		  </li>
		  <li>
		    <span
		      className={`text-sm font-medium text-gray-600 hover:bg-gray-100 p-3 rounded-xl ${
		        props.currentRange === "3M" ? "text-brand-500" : ""
		      }`}
		      onClick={() => props.changeRangeFunc("3M")}
		    >
		     <span style={{ color: props.currentRange === "3M" ? "#008ffb" : "initial" }} >3M</span>
		    </span>
		  </li>
		  <li>
		    <span
		      className={`text-sm font-medium text-gray-600 hover:bg-gray-100 p-3 rounded-xl`}
		      onClick={() => props.changeRangeFunc("1M")}
		    >
		    <span style={{ color: props.currentRange === "1M" ? "#008ffb" : "initial" }} >1M</span>
		    </span>
		  </li>
		</ul>

        </button>
        <button style={{"height" : "40px"}} className="!linear hidden md:block  z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">

        <div className="h-full w-full">
          <LineChart
            options={props.lineOption}
            series={props.lineData}
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
