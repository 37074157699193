import React from 'react';

const TriplePulsingLoadingBars = ({ height = 20, gap = 10 }) => {
  return (
    <div className="flex flex-col" style={{ gap: `${gap}px` }}>
      {[0, 1, 2].map((index) => (
        <div
          key={index}
          className="w-[310px] md:w-[400px] bg-blue-200 rounded-full overflow-hidden"
          style={{ height: `${height}px` }}
        >
          <div
            className="h-full bg-blue-600 rounded-full animate-pulse"
            style={{
              width: '100%',
              animationDelay: `${index * 0.15}s`,
              animationDuration: '1.5s',
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default TriplePulsingLoadingBars;