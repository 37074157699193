// import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import QuaterlyTable from "views/admin/tables/components/QuaterlyTable";
// import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";
import Widget from "components/widget/Widget";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import TaskCard from "views/admin/default/components/TaskCard";
// import tableDataCheck from "./variables/tableDataCheck.json";
// import tableDataComplex from "./variables/tableDataComplex.json";

const Dashboard = (props) => {
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Market Cap CR"}
          subtitle={props.stockData.ValuationMetrics.MarketCap ?  (props.stockData.ValuationMetrics.MarketCap/1000000).toFixed(0)  : "N/A"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Current Price"}
          subtitle={props.lineData[0].data ? props.lineData[0].data.slice(-1) : "N/A"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"P/E ratio"}
          subtitle={props.stockData.ValuationMetrics.PERatio ? props.stockData.ValuationMetrics.PERatio : "N/A"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"P/B ratio"}
          subtitle={props.stockData.ValuationMetrics.PriceToBook ? props.stockData.ValuationMetrics.PriceToBook : "N/A"}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Net Profit CR"}
          subtitle={props.stockData.FinancialStatements.NetIncome ? (props.stockData.FinancialStatements.NetIncome/10000000).toFixed(0): "N/A"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Revenue CR"}
          subtitle={props.stockData.FinancialStatements.TotalRevenue ? (props.stockData.FinancialStatements.TotalRevenue/10000000).toFixed(0): "N/A"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Profit Margin"}
          subtitle={props.stockData.ProfitabilityMetrics.NetProfitMargin ? (props.stockData.ProfitabilityMetrics.NetProfitMargin*100).toFixed(1) + "%" : "N/A"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Debt to Equity"}
          subtitle={props.stockData.DebtToEquity ? (props.stockData.DebtToEquity).toFixed(2): "N/A"}
        />

      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
        <TotalSpent
          lineData={props.lineData}
          lineOption={props.lineOption}
          changeRangeFunc={props.changeRangeFunc}
          currentRange={props.currentRange}
        />
        <DailyTraffic barChartData={props.barChartData} barChartOptions={props.barChartOptions} />
      </div>


      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        <div>
          <QuaterlyTable
            tableData={props.tableDataColumns}
            smalltitle="Quaterly Results"
            limit={50}
          />
        </div>

        <div>
          <QuaterlyTable
            tableData={props.yearlyData}
            smalltitle="Yearly Results"
            limit={50}
          />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          {/* <TaskCard /> */}
          <div className="grid grid-cols-1 rounded-[20px]">
            {/* <MiniCalendar /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
