import React, { useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { useNavigate } from 'react-router-dom';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

function ColumnsTable(props) {
  const navigate = useNavigate();
  const { tableData } = props;
  const [sorting, setSorting] = React.useState([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("Date", {
      id: "Date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Date</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("Sales", {
      id: "Sales",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Sales
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("operatingProfit", {
      id: "operatingProfit",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Operating Profit
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("operatingMargin", {
      id: "operatingMargin",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Operating Margin</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("EPS", {
      id: "EPS",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">EPS</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("Netprofitmargin", {
      id: "Netprofitmargin",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Net Profit Margin</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("Netprofit", {
      id: "Netprofit",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Net Profit</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  function cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [data, setData] = React.useState(() => [...defaultData]);
 
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (

    <Card extra={"w-full pb-8 p-8 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-3xl font-bold text-navy-700 dark:text-white">
          {props.smalltitle}
        </div>

      </header>

      <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-navy-700">
                        <span>
                            {cap(header.column.id)}
                          </span>
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, props.limit)
              .map((row) => {
                return (
                  <tr key={row.id} className="mx-4" style={row.id % 2 === 0 ? {'backgroundColor' : '#f7faff'} : {}}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                          // style={{'cursor' : 'pointer'}}
                        >
                          <span className={cell.column.id === 'Date' ? "cursor-pointer" : ""} >
                              {cell.column.id === 'Date' 
                              ? cell.row.original.Date 
                              : cell.column.id === 'Sales' 
                              ? cell.row.original.Sales 
                              : cell.column.id === 'operatingProfit' 
                              ? cell.row.original.OperatingProfit 
                              : cell.column.id === 'operatingMargin' 
                              ? cell.row.original.OperatingMargin 
                              : cell.column.id === 'EPS' 
                              ? cell.row.original.EPS 
                              : cell.column.id === 'Netprofitmargin' 
                              ? cell.row.original.NetProfitMargin 
                              : cell.column.id === 'Netprofit' 
                              ? cell.row.original.NetProfit
                              : null}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>

  );
}

export default ColumnsTable;
const columnHelper = createColumnHelper();
