import React, { useState , useEffect } from "react";
import axios from "axios";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import InputField from "components/fields/InputField";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';

export default function Login() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkauth = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {
            navigate("/aisearch")
        }
      }catch (error) {
          const sendOTP = async () => {
		      try {
		        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/sendotp`, {
		          "token": localStorage.getItem("token")
		        });

		        if (response.status === 200) {
		          console.log("OTP sent successfully");
		        } else {
		          console.log("Unexpected response status:", response.status);
		        }
		      } catch (error) {
		        console.error("Error sending OTP:", error);
		      }
	    };
	    sendOTP();
      }
      finally {
        setLoading(false);
      }
    };
    checkauth();
  }, [navigate]);


  const verify = async (event) => {
      event.preventDefault();
	  try {
	    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verifyotp`,{
	        "token": localStorage.getItem("token"),
            "otp" : otp
	    });
	    if (response.status === 200) {
	        navigate("/aisearch")
	    }
	  }catch (error) {
		if (error.response) {
            setError(error.response.data.message);
        }
	  }
  }


  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div
            className="pl-4 bg-brand-600"
            style={{ height: "55px", display: "flex", alignItems: "center" }}
          >
            <p className="text-xl font-bold text-navy-700 text-white">
              Stockefy
            </p>
          </div>
          <div className="relative flex items-center justify-center">
            <div className="mx-auto flex min-h-full w-full flex-col  pt-3 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 ">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className="mt-8 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
                  <div className="rounded-3xl shadow-xl border-2 p-10 w-full max-w-full flex-col items-center xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                      Verify OTP
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600">
                      Enter opt send to email address to register
                    </p>
                    {error && <p className="mb-3 text-red-500">{error}</p>}

                    <form onSubmit={verify}>
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="OTP"
                        placeholder="X X X X X"
                        id="otp"
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />

                      <button
                        type="submit"
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Verify OTP
                      </button>
                    </form>
                    <div className="mt-4">
                      <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                        Already registered?
                      </span>
                      <a
                        href="/"
                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                      >
                        Login
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
