import React from 'react';

const LoadingCircle = ({ variant = 'default', size = 'md', color = 'blue' }) => {
  const sizeClasses = {
    sm: 'w-6 h-6',
    md: 'w-10 h-10',
    lg: 'w-16 h-16',
  };

  const colorClasses = {
    blue: 'border-blue-500',
    green: 'border-green-500',
    red: 'border-red-500',
    yellow: 'border-yellow-500',
  };

  const baseClasses = `inline-block rounded-full border-4 border-t-transparent animate-spin ${sizeClasses[size]} ${colorClasses[color]}`;

  const variants = {
    default: baseClasses,
    dotted: `${baseClasses} border-dotted`,
    double: `${baseClasses} border-double`,
  };

  return <div className={variants[variant]} />;
};

const LoadingSpinner = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center space-y-4">
	    <div className="flex justify-center items-center h-24">
	      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
	    </div>
    </div>
  );
};

export default LoadingSpinner;