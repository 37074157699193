import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "components/navbar";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';
import Card from "components/card";
import { FiSearch} from "react-icons/fi";


function AiSearch() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");

  const handleInputChange = async (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);

    if (newQuery.length > 0) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/${newQuery}`);
        setResults(response.data.searchdata); 
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    const checkauth = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {

        }
      }catch (error) {
        navigate("/")
      }
      finally {
        setLoading(false);
      }
    };
    checkauth();
  }, [navigate]);

  const openStock = async (symbol) => {
    const sym = symbol.replace(".NS",'')
    navigate(`/aianalysis/${sym.toLowerCase()}`)
  }




  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
	        <Navbar
              logoText={"Horizon UI Tailwind React"}
              brandText="Stockefy"
            />
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  const mapping = {"Nifty50" : "Nifty 50" , "NiftyMid50" : "Nifty Midcap 50",
                   "NiftyMid100" : "Nifty Midcap 100" , "NiftySmallCap100" : "Nifty Smallcap 50"}

  return (
    <div className="flex w-full" style={{"height" : "100vh" ,overflow: 'hidden'}}>
      <div className=" w-full dark:!bg-navy-900" style={{'backgroundColor' : "#f2f5ff"}}>
        <main className={` flex-none transition-all md:pr-2`}>
          <div>
            <Navbar
              logoText={"hello"}
              brandText="Stockefy"
            />
            <Card extra={'mt-1 mx-2 h-full md:mx-12 p-6 md:p-12 flex items-center justify-center'} style={{"height" : "90vh"}}>
              <div className="max-w-[600px]">
              <div className='flex w-full items-center justify-center'>
                <p className='text-5xl md:text-6xl font-bold'>Stockefy AI</p>
              </div>
              <div className="flex w-full items-center justify-center mt-3 text-lg text-center">
                <p className="text-gray-700"> AI-powered stock analysis tool for Investors with Buisness , Annual, and Fundamental AI analysis under 30 secs. </p>
              </div>

               <div className="flex justify-center mt-4">
                  <div className="relative rounded-xl bg-white border-purple-600 border mx-4 md:mx-10 w-full md:w-3/5 shadow-md items-center justify-center">
                      <div className=" flex h-full items-center  text-navy-700 dark:bg-navy-900 dark:text-white">
                          <p className="pl-4 text-xl">
                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                          </p>
                          <input
          			        onChange={handleInputChange}
                            type="text"
                            placeholder="AI Search"
                            style={{"width":"70%"}}
                            className="p-3 block text-md font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
                          />
                          {results.length > 0 && (
                          <div className="z-20 absolute top-full w-full mt-2 bg-white rounded-md shadow-lg dark:bg-navy-800">
                            <ul>
                              {results.map((result) => (
                                <li
                                  key={result.symbol}
                                  className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-navy-700 cursor-pointer"
                                  onClick={() => openStock(result.symbol)}
                                >
                                  {result.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                  </div>
                  <div>
                  </div>
               </div>

               <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 mb-32 mt-6 ">
	               <Card extra="border border-gray-600 p-2 rounded-xl shadow-md hover:border-purple-600 transition-all duration-250 delay-200 cursor-pointer" onClick={() => openStock("ULTRACEMCO.NS")}>
	                  <div className="h-50 flex  w-auto flex-col justify-center text-center">
				        <p className="font-dm text-md font-medium text-gray-600 hover:text-purple-600 transition-all duration-250 delay-200">UltraTech Cement</p>
				      </div>
	               </Card>

	               <Card extra="border border-gray-600 p-2 rounded-xl shadow-md hover:border-purple-600 transition-all duration-250 delay-200 cursor-pointer" onClick={() => openStock("ASIANPAINT.NS")}>
	                  <div className="h-50 flex  w-auto flex-col justify-center text-center">
				        <p className="font-dm text-md font-medium text-gray-600 hover:text-purple-600 transition-all duration-250 delay-200">Asian Paints</p>
				      </div>
	               </Card>

	               <Card extra="border border-gray-600 p-2 rounded-xl shadow-md hover:border-purple-600 transition-all duration-250 delay-200 cursor-pointer" onClick={() => openStock("BAJFINANCE.NS")}>
	                  <div className="h-50 flex  w-auto flex-col justify-center text-center">
				        <p className="font-dm text-md font-medium text-gray-600 hover:text-purple-600 transition-all duration-250 delay-200">Bajaj Finance</p>
				      </div>
	               </Card>

	               <Card extra="border border-gray-600 p-2 rounded-xl shadow-md hover:border-purple-600 transition-all duration-250 delay-200 cursor-pointer" onClick={() => openStock("RELIANCE.NS")}>
	                  <div className="h-50 flex  w-auto flex-col justify-center text-center">
				        <p className="font-dm text-md font-medium text-gray-600 hover:text-purple-600 transition-all duration-250 delay-200">Reliance</p>
				      </div>
	               </Card>

	               <Card extra="border border-gray-600 p-2 rounded-xl shadow-md hover:border-purple-600 transition-all duration-250 delay-200 cursor-pointer" onClick={() => openStock("HDFCBANK.NS")}>
	                  <div className="h-50 flex  w-auto flex-col justify-center text-center">
				        <p className="font-dm text-md font-medium text-gray-600 hover:text-purple-600 transition-all duration-250 delay-200">HDFC</p>
				      </div>
	               </Card>

	               <Card extra="border border-gray-600 p-2 rounded-xl shadow-md hover:border-purple-600 transition-all duration-250 delay-200 cursor-pointer" onClick={() => openStock("COALINDIA.NS")}>
	                  <div className="h-50 flex  w-auto flex-col justify-center text-center">
				        <p className="font-dm text-md font-medium text-gray-600 hover:text-purple-600 transition-all duration-250 delay-200">Coal India</p>
				      </div>
	               </Card>
               </div>
              </div>
            </Card>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AiSearch;
